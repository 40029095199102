import React from 'react'
import Image from 'gatsby-image'
import styled from '@emotion/styled'
import { Global, css } from '@emotion/core'
import emotionNormalize from 'emotion-normalize'
import 'fontsource-playfair-display'
import 'fontsource-quicksand'

export const sizes = {
  pageWidth: '1200px',
}

export const colors = {
  dark: 'black',
  light: 'white',
}

export const breakpoints = {
  s: 576,
  m: 768,
  l: 992,
  xl: 1200,
}

export const GlobalStyle = props => (
  <Global
    {...props}
    styles={css`
      ${emotionNormalize}
      body {
        margin: 0;
        height: 100vh;
      }
      html {
        font-family: 'Quicksand', sans-serif;
        -ms-text-size-adjust: 100%;
        -webkit-text-size-adjust: 100%;
      }
    `}
  />
)

export const Img = styled(Image)`
  max-width: 100%;
  margin-left: 0;
  margin-right: 0;
  margin-top: 0;
  padding-bottom: 0;
  padding-left: 0;
  padding-right: 0;
  padding-top: 0;
  margin-bottom: 1.45rem;
`

export const Container = styled.div`
  margin: 0 auto;
  max-width: ${sizes.pageWidth};
`

export const TwoColumnGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 2rem 1fr;
  grid-template-rows: 1auto;
  grid-template-areas: 'left . right';

  @media (max-width: ${breakpoints.l}px) {
    display: block;
  }
`

export const GridLeft = styled.div`
  grid-area: left;
`

export const GridRight = styled.div`
  grid-area: right;
`

export const MainContent = styled.main`
  margin-top: 80px;
  margin-bottom: 40px;

  @media (max-width: ${breakpoints.l}px) {
    margin-top: 40px;
    margin-bottom: 20px;
  }
`

export const Col = styled.div`
  width: 50%;
  @media (max-width: ${breakpoints.m}px) {
    width: 100%;
  }
`
export const ColText = styled(Col)`
  padding: 0 2em;
  font-size: 1.2em;
  @media (max-width: ${breakpoints.m}px) {
    padding: 0;
  }
`
export const TwoColContainer = styled.div`
  display: flex;
  flex-direction: row;
  @media (max-width: ${breakpoints.m}px) {
    flex-direction: column;
  }
`
