import styled from '@emotion/styled'
import { Link } from 'gatsby'

import { breakpoints, colors, sizes } from '../../utils/styles'

export const Wrapper = styled.div`
  padding-bottom: 1.45rem;
`

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: baseline;
  padding: 1.45em 0;
  margin: 0 auto;
  max-width: ${sizes.pageWidth};
  @media (min-width: ${breakpoints.m}px) {
    flex-direction: row;
  }
`

export const MenuLink = styled(Link)`
  color: ${colors.dark};
  text-decoration: none;
  font-size: 1.8em;
  font-weight: 400;
  margin-right: 1em;

  @media (max-width: ${breakpoints.s}px) {
    font-size: 1.4rem;
  }
  @media (max-width: 500px) {
    font-size: 1rem;
  }
  :hover {
    text-decoration: underline;
  }
`
export const SiteHeader = styled(MenuLink)`
  font-family: 'Playfair Display';

  font-size: 3em;

  @media (max-width: ${breakpoints.s}px) {
    font-size: 12rem;
  }
  @media (max-width: 500px) {
    font-size: 1.5rem;
  }
`

export const CartCounter = styled.span`
  background-color: white;
  color: #663399;
  border-radius: 20px;
  padding: 0 10px;
  font-size: 1.2rem;

  z-index: 20;
`
