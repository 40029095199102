import React from 'react'
import styled from '@emotion/styled'
import { Wrapper } from '../../utils/styles'

const FooterWrapper = styled.footer`
  height: 50px;
`

const SiteFooter = () => {
  return (
    <Wrapper>
      <FooterWrapper>
        © {new Date().getFullYear()}, Design by
        {` `}
        <a href="https://www.gabrieldavsion.com">Gabriel Davison</a>
      </FooterWrapper>
    </Wrapper>
  )
}

export default SiteFooter
