import React, { useContext } from 'react'
import reduce from 'lodash/reduce'
import PropTypes from 'prop-types'

import StoreContext from '~/context/StoreContext'
import { CartCounter, Container, MenuLink, Wrapper, SiteHeader } from './styles'
import SocialIcons from '../SocialIcons/SocialIcons'

const useQuantity = () => {
  const {
    store: { checkout },
  } = useContext(StoreContext)
  const items = checkout ? checkout.lineItems : []
  const total = reduce(items, (acc, item) => acc + item.quantity, 0)
  return [total !== 0, total]
}

const Navigation = ({ siteTitle }) => {
  const [hasItems, quantity] = useQuantity()

  return (
    <Wrapper>
      <Container>
        <div>
          <SiteHeader to="/">{siteTitle}</SiteHeader>
        </div>
        <div>
          <MenuLink to="/shop">Shop</MenuLink>
          <MenuLink to="/about">About</MenuLink>
          <MenuLink to="/contact">Contact</MenuLink>
          <MenuLink to="/cart">
            Cart
            {hasItems && <CartCounter>( {quantity} )</CartCounter>}
          </MenuLink>
          <SocialIcons />
        </div>
      </Container>
    </Wrapper>
  )
}

Navigation.propTypes = {
  siteTitle: PropTypes.string,
}

Navigation.defaultProps = {
  siteTitle: ``,
}

export default Navigation
