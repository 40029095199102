import React from 'react'
import fbIcon from '../../images/icon-facebook.png'
import instaIcon from '../../images/icon-instagram.png'
import styled from '@emotion/styled'
import { breakpoints } from '../../utils/styles'

const SocialIconsContainer = styled.div`
  display: inline-block;

  img {
    width: 30px;
  }
  a {
    text-decoration: none;
    margin: 0;
  }
  @media (max-width: ${breakpoints.xl}px) {
    margin: 0;
  }

  @media (max-width: ${breakpoints.s}px) {
    img {
      width: 20px;
    }
  }
`

const SocialIcons = () => {
  return (
    <SocialIconsContainer>
      <a href="https://www.instagram.com/vintageflowerpaintings/">
        <img alt="Instagram link" src={instaIcon} />
      </a>
      <a href="https://www.facebook.com/vintageflowerpaintings/">
        {' '}
        <img alt="Facebook link" src={fbIcon} />
      </a>
    </SocialIconsContainer>
  )
}

export default SocialIcons
